import dayjs from "dayjs/esm";
import "dayjs/esm/locale/fr";
import {debounce} from "./utils";
import {
  prevTimeline, prevStepTimeline, nextTimeline, nextStepTimeline, toggleTimeline, refreshTimeline
} from "./timeline";
import {
  toggleLayer,
  geolocateMe
} from "./data";
import {toggleMap} from "./map";
import {displaySensorCharts} from "./sensor";
import {updateLayout, sliderDisplay, initSlides, createSlides, displayNews} from "./common";
import {
  advertisersRefs, config
} from "./config";
import router from "./router";
import MicroModal from "micromodal";
import {closePoi, removeActivePoi, setDayNight, setFavorite} from "./meteogramme";
import weatherIcons from "../img/weather/*.svg";
// import * as Sentry from "@sentry/browser";
// import {Integrations} from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://226f2354cdc842db97ac896999739be9@o21346.ingest.sentry.io/5807697",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.5
// });

dayjs.locale('fr');

document.addEventListener("DOMContentLoaded", function(evt) {
  // document.querySelector("#toggle_map_menu").addEventListener("click", function(evt) {
  //   toggleMapNav();
  // });
  document.querySelector("#map_favorite button").addEventListener("click", function(evt) {
    goToFavorite(config.favoritePath);
  });
  initSlides(function (){
    createSlides(advertisersRefs,"partners")
    sliderDisplay("partners");
  });
  window.wm = {
    toggleLayer: toggleLayer,
    toggleMap: toggleMap,
    geolocateMe: geolocateMe,
    prevTimeline: prevTimeline,
    prevStepTimeline: prevStepTimeline,
    nextTimeline: nextTimeline,
    nextStepTimeline: nextStepTimeline,
    toggleTimeline: toggleTimeline,
    refreshTimeline: refreshTimeline,
    toggleLines: toggleLines,
    displaySensorCharts: displaySensorCharts,
    setFavorite: setFavorite,
    // setDayNight: setDayNight,
    closePoi: closePoi,
    displayNews: displayNews
  };
});

var windowResized = debounce(function() {
  updateLayout();
}, 800);
window.onresize = windowResized;

function toggleMapNav() {
  var navElt = document.querySelector("#map_container #map_menu");
  if (navElt) navElt.classList.toggle("expanded");
}

function toggleLines(btn, ...cls) {
  let c, line;
  for (c of cls) {
    let lines = Array.from(document.querySelectorAll("#weather_details_header ." + c + ", #weather_details ." + c));
    for (line of lines) {
      if (line.style.display === "none") {
        line.style.display = "flex";
      } else {
        line.style.display = "none";
      }
    }
  }
  btn.classList.toggle('active');
}


function favoriteDetails() {
    removeActivePoi();
    document.querySelector("#map_modal").setAttribute("data-modal", "favorite");
    document.querySelector("#map_modal_title").innerHTML = "Définir son plan d’eau favori";
    document.querySelector("#header_links").innerHTML = '<button class="modal__close" aria-label="Fermer" data-micromodal-close></button>';
    document.querySelector("#map_modal_content").innerHTML =
      '<div>Cliquez sur le pictogramme météo <img class="img-icon" src="' + weatherIcons["soleil"] + '" alt="Soleil">  de votre choix. Puis cliquez sur le bouton « Définir comme favori »</div>';
    MicroModal.show('map_modal', {disableScroll: true, onClose: () => { router.navigate("/"); }});
}

function goToFavorite(path) {
  if (path) {
    router.navigate(path);
  } else {
    var menuBtn = document.querySelector("#weather_fc");
    if(!menuBtn.classList.contains("active")){
      wm.toggleLayer(menuBtn, 'weather_fc');
    }
    favoriteDetails();
  }
}
